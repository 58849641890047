import React, { FC } from 'react'
import { normalize } from 'polished'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import theme from '../styles/theme'

const GlobalStyle = createGlobalStyle`
	${normalize()};

	@font-face {
    font-family: "Overpass";
    src: url("/static/Overpass-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

	html, body {
		position: relative;
		height: 100%;
		font-family: 'Optima', sans-serif;
		line-height: 1.3;
	}

	#___gatsby {
		height: 100%;
	}

	#gatsby-focus-wrapper {
		height: 100%;
	}
`

const Layout: FC<{}> = ({ children }) => (
	<>
		<GlobalStyle />
		<ThemeProvider theme={theme}>{children}</ThemeProvider>
	</>
)

export default Layout
